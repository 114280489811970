@import "~bootstrap/scss/mixins";
@import 'variables';

.property-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $spacer * 2;
}

.property-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.property-group {
  margin-top: $spacer / 2;
}

.property-type + .property-group {
  margin-top: $spacer;
}

.property-group {
  display: flex;

  .property-group-name {
    flex: 1;
  }
  
  .property-group-value {
    border-left: 1px solid $border-color;
    flex: 0 0 8rem;
    border-radius: 0;
  }

  .btn {
    flex: 0 0 1.6rem;
    border-radius: 0 $border-radius $border-radius 0;
  }

}