@import "~bootstrap/scss/mixins";
@import 'variables';

html {
  .no-wrap {
    white-space: nowrap;
  }

  -webkit-font-smoothing: antialiased;
  .environment-notice {
    text-transform: uppercase;
    color: $link-hover-color;
    font-weight: 800;
    position: fixed;
    top: -1px;
    right: -1px;
    background-color: $white;
    border: 1px solid $border-color;
    padding: 0 $spacer;
    cursor: help;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
  }

  #main-nav {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1.25rem 0.625rem;

    .nav-item > .nav-link {
      text-transform: uppercase;
    }

    .navbar-toggler {
      color: $link-hover-color;
      font-size: 1.125rem;
    }

    .navbar-brand {
      padding: 0;
      img {
        width: auto;
        height: 43px;
      }
    }
    .current-user-link {
      @include media-breakpoint-up(lg) {
        margin-left: $spacer * 2;
      }
      .current-staff {
        display: flex;
        align-items: center;
        i {
          margin-right: $spacer / 3;
        }
        &:before {
          display: none;
        }
      }
    }


    @include media-breakpoint-up(sm) {
      .navbar-brand {
        img {
          height: 63px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .navbar-brand {
        margin-left: -0.9375rem;
      }
      li.nav-item {
        display: flex;
        align-items: center;
      }
      a.nav-link {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0.5rem;
          right: 0.5rem;
          bottom: 0.4rem;
          height: 2px;
        }
        &.active:before {
          background-color: $navbar-light-active-color;
        }

        &:hover:before {
          background-color: $link-hover-color;
        }
      }
    }
  }

  body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    header {
      background-color: $primary;
      color: #fff;
      padding: 0.75rem 0;
      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      h1 {
        margin: 0;
        font-size: 1.2rem;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: 2rem;
        }
      }
      .btn-toolbar {
        border-left: 1px solid darken($primary, 2%);
        padding-left: $spacer;
        a.btn {
          text-transform: uppercase;
          font-weight: 600;
          @include media-breakpoint-down(md) {
            i {
              font-size: 1.2rem;
            }
          }
        }
      }
    }

    main {
      flex-grow: 1;
      padding-top: $spacer * 1.5;
      padding-bottom: $spacer * 1.5;
    }

    footer {
      margin-top: auto;
      padding-top: $spacer;
      padding-bottom: $spacer;
      background-color: #323232;
      color: #fff;
      a {
        color: #fff;
        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }
}
