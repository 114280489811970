@import './variables';

.draggableDocument, .draggableGrowthRun {
  .draggableArea {
    flex: 0 0 1.8rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem $spacer / 6 0.6rem calc(#{$spacer} / 6 - 2px);
    background-color: $gray-400;
    color: $gray-600;
  }

  &.dragging {
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.3);
  }
}

.draggableDocument.unselected, .nonDraggableDocument.unselected {
  opacity: 0.5;
}

.draggableDocument, .draggableGrowthRun, .nonDraggableDocument {
  display: flex;
  background-color: $gray-100;
  align-items: center;
  margin-bottom: 0.4rem;
  
  .draggableContent {
    padding-left: $spacer / 2;
    flex: 1;
    font-size: 1.1rem;
    
    .custom-control-label {
      &::after, &::before {
        top: 0.35rem;
      }
    }
  }
  
  &:hover {
    background-color: $gray-200;
  }
}

.nonDraggableDocument {
  .nonDraggableSpacer {
    flex: 0 0 2rem;
    text-align: center;
    padding: 0.6rem $spacer / 6 0.6rem calc(#{$spacer} / 6 - 2px);
    background-color: $gray-300;
    color: $gray-500;
    height: 41px;
  }
}