@import './variables';

.machineLogs {
  margin-top: 2rem;
  .machineLog {
    position: relative;
    display: flex;
    &.up, &.nowUp{
      .machineLogTimeline i {
        color: #6bb76b;
      }
    }
    &.down, &.nowDown {
      .machineLogTimeline i {
        color: #c32b2b;
      }
    }
    .machineLogTimeline {
      flex: 0 0 28px;
      &::before, &::after {
        content: '';
        width: 6px;
        background: #e2e2e2;
        position: absolute;
        left: 11px;
      }
      &::before {
        top: 0px;
        height: calc(50% - 11px);
      }
      &::after {
        bottom: 0px;
        height: 50%;
      }
      i {
        position: absolute;
        left: 2px;
        top: calc(50% - 15px);
        font-size: 1.2rem;
        z-index: 1000;
      }
    }
    &.down {
      .machineLogTimeline {
        &::before, &::after {
          background: #f3d4d4;
        }
      }
    }
    &.nowUp {
      .machineLogTimeline {
        &::after {
          background: #f3d4d4;
          background: linear-gradient(180deg, #e2e2e2 0%, #e2e2e2 30%, #f3d4d4 100%);
        }
      }
    }
    &.nowDown {
      .machineLogTimeline {
        &::before {
          background: #f3d4d4;
        }
      }
    }
    .machineLogBody {
      flex: 1;
      padding: 0.4rem 0.8rem;
      background-color: $gray-200;
      margin-bottom: 0.8rem;
  
      p {
        padding: 0.2rem;
        margin: 0;
      }
      
      .machineLogDetails {
        display: flex;
        padding: 0.2rem 0.4rem;
        .machineLogType {
          flex: 0 0 24rem;
          text-align: left;
          span {
            margin-left: 0.4rem;
          }
        }
        .timeLogged {
          flex: 1;
          text-align: right;
        }
      }
    }
    &:first-of-type {
      .machineLogTimeline {
        &::before {
          height: calc(50% + 12px);
          top: -14px;
          background: #e2e2e2;
          background: linear-gradient(180deg, rgba(226, 226, 226, 0) 0%, rgba(226, 226, 226, 1) 45%, rgba(226, 226, 266, 1) 100%);
        }
      }
    }
    &:last-of-type {
      .machineLogTimeline {
        &::before {
          height: 50%;
        }
      }
    }
  }
}

.logDocuments {
  display: flex;
  flex-wrap:  wrap;
  a {
    margin-right: 0.4rem;
    margin-top: 0.4rem;
  }
}