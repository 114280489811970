@import "~bootstrap/scss/mixins";
@import 'variables';

.navbar-expand-lg {
  @include media-breakpoint-down(md) {
    .navbar-collapse {
      margin-left: -0.625rem;
      margin-right: -0.625rem;
      margin-top: $spacer;
      margin-bottom: -$spacer;
      align-items: flex-start;
      background-color: $gray-100;
      padding: $spacer / 2 $spacer;
      border-top: 1px solid $gray-400;

      .navbar-nav {
        flex-direction: column;
        .current-user-link {
          order: -1;
          .current-staff {
            i {
              font-size: 1rem;
            }
          }
        }
        .dropdown-menu {
          position: static;
          float: none;
          min-width: auto;
          border: none;
          border-radius: 0px;
          background-color: transparent;
          .dropdown-item {
            padding: 0;
          }
        }
      }
    }
  }
}
